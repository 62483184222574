import { ImageProps } from 'next/image';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import { Button } from '../Button';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TStoryCtaProps = {
  icon?: ImageProps | null;
  title?: ReactNode | string;
  action?: React.ComponentProps<typeof Button>;
};

const StoryCTA: React.FC<TStoryCtaProps> = props => {
  const { icon, title, action } = props;

  if (!icon && !title && !action) {
    return null;
  }

  const storyCtaContent = (
    <>
      {icon && (
        <ImageWithPlaceholder
          className='absolute end-4 top-4 h-4 w-4 object-contain object-center'
          {...icon}
        />
      )}

      {title && (
        <span
          className={
            'text-center text-lg font-medium leading-normal text-brand-500'
          }
        >
          {title}
        </span>
      )}
    </>
  );
  const className =
    'relative flex h-[9.5rem] w-[9.5rem] flex-col items-center justify-center rounded-4xl border-2 border-brand-250 p-4 transition-colors hover:border-brand-500';
  return action && 'href' in action ? (
    <Link {...action} className={className}>
      {storyCtaContent}
    </Link>
  ) : (
    <button {...action} className={className}>
      {storyCtaContent}
    </button>
  );
};

export default StoryCTA;
