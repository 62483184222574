import { VideoCollection } from '@shared/master-types';
import clsx from 'clsx';
import { format } from 'date-fns/format';
import React from 'react';

import { ReactComponent as IconYoutubePlayButton } from '../../../../public/icons/youtube-play-button.svg';
import toImageProps from '../../../utils/toImageProps';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';
import { ETagSize, ETagTheme, ETagVariant, Tag } from '../Tag';

export type TVideoCardProps = {
  video: VideoCollection;
  className?: string;
  onClick?: (video: VideoCollection) => void;
};

export const VideoCard: React.FC<TVideoCardProps> = props => {
  const { video, className, onClick } = props;

  const hasVideoCover = typeof video.cover !== 'string';
  const imageWithProps = hasVideoCover && toImageProps(video.cover);
  const clickHandler = (): void => {
    if (typeof onClick !== 'undefined') {
      onClick(video);
    }
  };
  return (
    <div
      onClick={clickHandler}
      className={clsx('flex items-center gap-4', className)}
    >
      <div className='relative aspect-video w-[7.75rem] shrink-0 overflow-hidden rounded-lg lg:w-[12.5rem] lg:rounded-[1.25rem]'>
        <div className='absolute left-0 top-0 h-full w-full cursor-pointer'>
          <div className='absolute h-full w-full bg-[#0F131C4D]/30'></div>
          <div className='absolute inset-0 m-auto h-6 w-[2.1875rem] opacity-60 hover:opacity-100 lg:h-[2.1875rem] lg:w-[3.125rem]'>
            <IconYoutubePlayButton />
          </div>
          {imageWithProps && (
            <ImageWithPlaceholder
              {...imageWithProps}
              alt={video.name}
              itemProp='image'
              className='pointer-events-none h-full w-full object-cover'
            />
          )}
        </div>
      </div>
      <div className='shrink-1'>
        <p className='line-clamp-3 text-base font-semibold leading-normal text-interface-1000'>
          {video.name}
        </p>
        {(!!video?.tags?.length || video?.releaseDate) && (
          <div className='flex flex-wrap items-center gap-2 pt-2'>
            {!!video?.tags?.length && (
              <div className='empty:hidden'>
                {video?.tags.map(
                  (tag, index) =>
                    typeof tag.value !== 'string' && (
                      <Tag
                        key={index}
                        variant={ETagVariant.Contained}
                        theme={ETagTheme.Secondary}
                        size={ETagSize.Small}
                      >
                        {tag.value.name}
                      </Tag>
                    ),
                )}
              </div>
            )}
            {video?.releaseDate && (
              <time
                dateTime={video.releaseDate}
                className='text-xs font-medium leading-relaxed text-interface-800'
                itemProp='datePublished'
              >
                {format(new Date(video.releaseDate), 'dd.MM.yyyy')}
              </time>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
