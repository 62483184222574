import React from 'react';

import { Button } from '../../../atoms/Button';
import { TBannerCardBgV2Props } from '../types';

const Buttons: React.FC<Pick<TBannerCardBgV2Props, 'buttons'>> = props => {
  const { buttons } = props;

  if (!buttons?.length) {
    return null;
  }

  return (
    <div className='flex flex-1 items-end'>
      <div className='flex flex-row flex-wrap items-start gap-5 md:flex-row lg:items-center'>
        {buttons.map((button, index) => (
          <Button key={index} {...button}>
            {button.children}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Buttons;
