import clsx from 'clsx';
import { cva } from 'cva';
import React, { useEffect, useState } from 'react';

import {
  EEyebrowSize,
  EEyebrowVariant,
  Eyebrow,
} from '../../../../atoms/Eyebrow';
import { ETitlePartsSize, TitlePart } from '../../../../atoms/TitlePart';

export const enum EWalletCardTheme {
  Light = 'light',
  Accent550 = 'accent550',
  Accent500 = 'accent500',
}

export type TWalletCardProps = {
  title?: string;
  description?: React.JSX.Element | null | string;
  eyebrows?: string[];
  theme?: EWalletCardTheme;
  activeTheme?: EWalletCardTheme;
  highlighted?: boolean;
};

export const walletCardCVA = cva(
  'rounded-4xl p-8 flex flex-col max-w-[24.5rem] transition-colors shrink',
  {
    variants: {
      theme: {
        [EWalletCardTheme.Light]: 'bg-surface-100',
        [EWalletCardTheme.Accent550]: 'bg-accent-550',
        [EWalletCardTheme.Accent500]: 'bg-accent-500',
      },
    },
  },
);

const WalletCard: React.FC<TWalletCardProps> = props => {
  const {
    title,
    description,
    eyebrows,
    theme = EWalletCardTheme.Light,
    activeTheme = EWalletCardTheme.Accent500,
    highlighted = false,
  } = props;

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    setIsHighlighted(highlighted);
  }, [highlighted]);

  if (!eyebrows?.length && !description && !title) {
    return null;
  }

  const currentTheme = isHighlighted ? activeTheme : theme;

  return (
    <div className={walletCardCVA({ theme: currentTheme })}>
      {!!eyebrows?.length && (
        <div className='mb-2 flex flex-wrap gap-x-6 gap-y-4'>
          {eyebrows.map((eyebrow, index) => (
            <Eyebrow
              variant={EEyebrowVariant.TextTracking}
              size={EEyebrowSize.Medium}
              className={clsx('transition-colors', {
                '!text-accent-800': currentTheme === EWalletCardTheme.Accent500,
                '!text-accent-850': currentTheme === EWalletCardTheme.Accent550,
                '!text-interface-500': currentTheme === EWalletCardTheme.Light,
              })}
              key={`${eyebrow}-${index}`}
            >
              {eyebrow}
            </Eyebrow>
          ))}
        </div>
      )}
      {title && <TitlePart size={ETitlePartsSize.Ml}>{title}</TitlePart>}
      {description && (
        <div className='font-regular mt-auto hidden pe-2 pt-2 text-sm leading-mega-loose lg:block'>
          {description}
        </div>
      )}
    </div>
  );
};
export default WalletCard;
