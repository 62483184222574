export const enum ESolidTabSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const enum ESolidTabTheme {
  Ordinary = 'ordinary',
  Sticked = 'sticked',
}
