import clsx from 'clsx';
import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { TBannerCardBgV2Space } from '../types';

type TBannerCardBgV2SpaceProps = TBannerCardBgV2Space;

const Block: React.FC<TBannerCardBgV2SpaceProps> = props => {
  const { image, text } = props;

  return (
    <div className='w-full'>
      {image && (
        <div
          className={clsx(
            'relative inline-flex w-full items-center justify-center gap-4 overflow-hidden ',
          )}
        >
          <ImageWithPlaceholder {...image} className='object-contain' />
        </div>
      )}

      {text && (
        <div className='inline-flex items-start justify-start gap-2 pe-2 text-base leading-extra-loose text-interface-700 empty:hidden'>
          {text}
        </div>
      )}
    </div>
  );
};

export default Block;
