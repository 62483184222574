import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import CardWithQuoteAuthor, {
  TCardWithQuoteAuthorProps,
} from './CardWithQuoteAuthor';
import CardWithQuoteStatistics, {
  TCardWithQuoteStatisticsCards,
} from './CardWithQuoteStatistic';
import {
  EQuoteWithCardIconSize,
  EQuoteWithCardPaddings,
  EQuoteWithCardStatsSize,
  EQuoteWithCardTheme,
} from './constants';
import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../Eyebrow';
import { Icon } from '../Icon';
import { EIconBottomPadding, EIconSize } from '../Icon/Icon.constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TCardWithQuoteProps = {
  title: string;
  topIcon?: ImageProps | null;
  topImage?: ImageProps | null;
  description: React.JSX.Element | string | null;
  eyebrows?: {
    eyebrow: string;
  }[];
  cardPaddings?: `${EQuoteWithCardPaddings}`;
  theme?: `${EQuoteWithCardTheme}`;
  topIconSize?: `${EQuoteWithCardIconSize}`;
  author: TCardWithQuoteAuthorProps;
  statistic?: TCardWithQuoteStatisticsCards[];
  statisticSize?: `${EQuoteWithCardStatsSize}`;
};

const CardWithQuote: React.FC<TCardWithQuoteProps> = props => {
  const {
    title,
    topIcon,
    topImage,
    description,
    eyebrows,
    author,
    cardPaddings = EQuoteWithCardPaddings.Yes,
    theme = EQuoteWithCardTheme.CardWithQuote1,
    topIconSize = EQuoteWithCardIconSize.Xl,
    statistic,
    statisticSize,
  } = props;

  return (
    <div
      className={clsx('flex flex-col items-center', {
        'px-10 py-12 md:py-16 xl:px-20 2xl:px-[6.5rem] 2xl:py-18':
          cardPaddings === EQuoteWithCardPaddings.Yes,
        'rounded-[2rem] border-2 border-control-200 bg-interface-50':
          theme === EQuoteWithCardTheme.CardWithQuote1,
      })}
    >
      {(topIcon || topImage) && (
        <div className='flex items-center gap-4'>
          {topIcon && (
            <Icon
              size={
                topIconSize === EQuoteWithCardIconSize.Xl
                  ? EIconSize.Xl
                  : EIconSize.OneXl
              }
              bottomPadding={EIconBottomPadding.No}
              icon={topIcon}
            />
          )}
          {topImage && (
            <ImageWithPlaceholder
              className='h-12 w-full max-w-[21.438rem] object-cover'
              height={48}
              width={343}
              {...topImage}
            />
          )}
        </div>
      )}
      {!!eyebrows?.length && (
        <div className='mt-4 flex justify-center gap-4'>
          {eyebrows.map((item, key) =>
            item.eyebrow ? (
              <Eyebrow
                variant={EEyebrowVariant.TextTracking}
                size={EEyebrowSize.Medium}
                key={key}
              >
                {item.eyebrow}
              </Eyebrow>
            ) : null,
          )}
        </div>
      )}
      <h3 className='mt-3 text-center text-3xl font-normal leading-[2.025rem] text-interface-1000 md:text-7xl md:leading-relaxed'>
        {title}
      </h3>
      {description && (
        <div
          className={clsx(
            'mt-3 text-center text-base font-normal leading-mega-loose text-interface-800 lg:max-w-[38rem]',
          )}
        >
          {description}
        </div>
      )}
      {author && (
        <CardWithQuoteAuthor
          name={author?.name}
          position={author?.position}
          icon={author?.icon}
        />
      )}
      {!!statistic?.length && (
        <CardWithQuoteStatistics
          statisticCards={statistic}
          size={statisticSize}
        />
      )}
    </div>
  );
};

export default CardWithQuote;
