import { cva } from 'cva';
import React from 'react';

import Blocks from './components/Blocks';
import Buttons from './components/Buttons';
import Eyebrows from './components/Eyebrows';
import Image from './components/Image';
import SmallImagesAndTags from './components/SmallImagesAndTags';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import { EHorizontalCardBgV2Theme, EHorizontalCardBgV2Type } from './constants';
import { Icon } from '../../atoms/Icon';
import { EIconBottomPadding, EIconSize } from '../../atoms/Icon/Icon.constants';

import type {
  THorizontalCardBgV2Props,
  THorizontalCardBgV2TypeA,
  THorizontalCardBgV2TypeB,
} from './types';

export const cardCVA = cva(
  'flex flex-row flex-wrap rounded-3xl xl:flex-nowrap 2xl:flex-nowrap 2xl:gap-4',
  {
    variants: {
      theme: {
        [EHorizontalCardBgV2Theme.Theme1]: 'bg-surface-50',
        [EHorizontalCardBgV2Theme.Theme2]: 'bg-surface-100',
      },
    },
  },
);

const HorizontalCardBgV2: React.FC<THorizontalCardBgV2Props> = props => {
  const { blocks, eyebrows, icon, subtitle, title, buttons, type, theme } =
    props;
  const { image, imageRounding, imageBackground } =
    props as THorizontalCardBgV2TypeA;
  const { tags, smallImages } = props as THorizontalCardBgV2TypeB;

  return (
    <div className={cardCVA({ theme })}>
      <div className='p-8'>
        <Icon
          bottomPadding={EIconBottomPadding.Yes}
          icon={icon}
          size={EIconSize.XxxL}
        />

        <Eyebrows eyebrows={eyebrows} />

        <Title title={title} />

        <Subtitle subtitle={subtitle} />

        <Blocks blocks={blocks} />

        <Buttons buttons={buttons} />
      </div>

      {type === EHorizontalCardBgV2Type.A && (
        <Image
          image={image}
          imageRounding={imageRounding}
          imageBackground={imageBackground}
        />
      )}

      {type === EHorizontalCardBgV2Type.B && (
        <SmallImagesAndTags smallImages={smallImages} tags={tags} />
      )}
    </div>
  );
};

export default HorizontalCardBgV2;
