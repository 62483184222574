import { LinkProps } from 'next/link';
import React from 'react';

import NewsCardBottomPart from './NewsCardBottomPart';
import NewsCardContentPart from './NewsCardContentPart';
import NewsCardCoverPart from './NewsCardCoverPart';
import { ENewsCardSize } from './constants';

export type TTag = {
  children: React.ReactNode;
  id: string;
  href?: string;
};

export type TNewsCardProps = {
  title: string;
  cover: {
    src: string;
    alt: string;
  } | null;
  description: string;
  link: LinkProps;
  date: string;
  tags?: TTag[];
  cardSize?: ENewsCardSize;
};

const NewsCard: React.FC<TNewsCardProps> = props => {
  const {
    description,
    title,
    cover,
    date,
    tags,
    link,
    cardSize = ENewsCardSize.M,
  } = props;

  const renderLink = link.href;

  if (!renderLink) {
    return null;
  }

  return (
    <div className='flex w-full flex-col gap-5'>
      <NewsCardCoverPart link={link} cardSize={cardSize} cover={cover} />
      <div className='flex flex-col gap-4'>
        <NewsCardContentPart
          link={link}
          title={title}
          description={description}
        />
        <NewsCardBottomPart tags={tags} date={date} />
      </div>
    </div>
  );
};

export default NewsCard;
