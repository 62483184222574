import { EButtonTheme } from '../../atoms/Button';

export const enum EDefaultCardV2Size {
  L = 'L',
  M = 'M',
  S = 'S',
  Xs = 'XS',
}

export const enum EDefaultCardV2ImagePosition {
  Top = 'Top',
  Bottom = 'Bottom',
}

export enum EDefaultCardV2ImageSize {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

export const enum EDefaultCardV2TopIconSize {
  Xl3 = '3XL',
  Xl2 = '2XL',
  Xl1 = '1XL',
}

export const enum EDefaultCardV2RightIconSize {
  Xl = 'XL',
  L = 'L',
}

export const enum EDefaultCardV2TitleSize {
  Xl = 'XL',
  L = 'L',
  Ml = 'ML',
  M = 'M',
}

export const enum EDefaultCardV2Paddings {
  TopAndBottom = 'TopAndBottom',
  OnlyTop = 'Top',
  OnlyBottom = 'Bottom',
}

export const enum EDefaultCardV2Theme {
  Theme1 = 'Default-card-V2-1',
  Theme2 = 'Default-card-V2-2',
  Theme3 = 'Default-card-V2-3',
  Theme4 = 'Default-card-V2-4',
  Theme5 = 'Default-card-V2-5',
  Theme6 = 'Default-card-V2-6',
}

type TDefaultCardV2Theme = {
  cardBackground: string;
  eyebrowColor: string;
  titleColor: string;
  descriptionColor: string;
  buttonTheme: EButtonTheme;
  borderStyle: string;
};

export const THEMES: Record<EDefaultCardV2Theme, TDefaultCardV2Theme> = {
  [EDefaultCardV2Theme.Theme1]: {
    cardBackground: 'bg-surface-100',
    eyebrowColor: 'text-interface-500',
    titleColor: 'text-interface-1000',
    descriptionColor: 'text-interface-800',
    buttonTheme: EButtonTheme.Primary,
    borderStyle: '',
  },
  [EDefaultCardV2Theme.Theme2]: {
    cardBackground: 'bg-surface-50',
    eyebrowColor: 'text-interface-500',
    titleColor: 'text-interface-1000',
    descriptionColor: 'text-interface-800',
    buttonTheme: EButtonTheme.Primary,
    borderStyle: '',
  },
  [EDefaultCardV2Theme.Theme3]: {
    cardBackground: 'bg-transparent',
    eyebrowColor: 'text-interface-500',
    titleColor: 'text-interface-1000',
    descriptionColor: 'text-interface-800',
    buttonTheme: EButtonTheme.Primary,
    borderStyle: 'border-solid border-surface-200 border-2',
  },
  [EDefaultCardV2Theme.Theme4]: {
    cardBackground: 'bg-transparent',
    eyebrowColor: 'text-brand-50',
    titleColor: 'text-interface-50',
    descriptionColor: 'text-interface-50/80 [&_.custom-li]:before:bg-brand-50',
    buttonTheme: EButtonTheme.SecondaryOnColour,
    borderStyle: 'border-solid border-surface-50-tooltip/[.21] border-2',
  },
  [EDefaultCardV2Theme.Theme5]: {
    cardBackground: 'bg-surface-50-tooltip/[.21]',
    eyebrowColor: 'text-brand-50',
    titleColor: 'text-interface-50',
    descriptionColor: 'text-interface-50/80 [&_.custom-li]:before:bg-brand-50',
    buttonTheme: EButtonTheme.SecondaryOnColour,
    borderStyle: '',
  },
  [EDefaultCardV2Theme.Theme6]: {
    cardBackground: 'bg-transparent',
    eyebrowColor: 'text-interface-500',
    titleColor: 'text-interface-1000',
    descriptionColor: 'text-interface-800',
    buttonTheme: EButtonTheme.Primary,
    borderStyle: '',
  },
};
