import React from 'react';

import BlockTitle from './BlockTitle';
import { Icon } from '../../../atoms/Icon';
import {
  EIconBottomPadding,
  EIconSize,
} from '../../../atoms/Icon/Icon.constants';
import { THorizontalCardBgV2Block } from '../types';

type THorizontalCardBgV2BlockProps = THorizontalCardBgV2Block;

const Block: React.FC<THorizontalCardBgV2BlockProps> = props => {
  const { icon, description, title } = props;

  return (
    <div className='max-w-[22.5rem]'>
      <Icon
        bottomPadding={EIconBottomPadding.Yes}
        icon={icon}
        size={EIconSize.Xl}
      />

      <BlockTitle title={title} />

      {description && (
        <div className='inline-flex items-start justify-start gap-2 pe-2 text-base leading-extra-loose text-interface-700 empty:hidden prose-p:pb-4 last:prose-p:pb-0 prose-a:text-brand-500 prose-a:hover:text-brand-550 prose-li:pb-2 last:prose-li:pb-0'>
          {description}
        </div>
      )}
    </div>
  );
};

export default Block;
