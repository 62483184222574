import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import {
  EHorizontalCardV2AlignContent,
  EHorizontalCardV2ImageHeight,
  EHorizontalCardV2Paddings,
  EHorizontalCardV2Theme,
} from './HorizontalCardV2.constants';
import { useBetterMediaQuery } from '../../../hooks/useBetterMediaQuery';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { EIconBottomPadding, EIconSize } from '../../atoms/Icon/Icon.constants';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { TitlePart } from '../../atoms/TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../../atoms/TitlePart/TitlePart.constants';

export type THorizontalCardV2Props = {
  richTitle?: React.JSX.Element | string | null;
  image?: ImageProps | null;
  iconList?: (ImageProps | null)[];
  description?: React.JSX.Element | string | null;
  buttons?: React.ComponentProps<typeof Button>[];
  paddings: EHorizontalCardV2Paddings;
  theme: EHorizontalCardV2Theme;
  iconSize: EIconSize;
  imageHeight: EHorizontalCardV2ImageHeight;
  alignContent: EHorizontalCardV2AlignContent;
};

const HorizontalCardV2: React.FC<THorizontalCardV2Props> = props => {
  const {
    richTitle,
    iconList,
    iconSize,
    image,
    imageHeight,
    alignContent,
    theme,
    paddings,
    buttons,
    description,
  } = props;

  const firstIcon = iconList?.[0];
  const iconProps = {
    size: iconSize,
    bottomPadding: EIconBottomPadding.No,
  };
  const titlePartPosition =
    alignContent === EHorizontalCardV2AlignContent.Center
      ? ETitlePartsPosition.Center
      : ETitlePartsPosition.Left;
  const titlePartTheme =
    theme === EHorizontalCardV2Theme.HorizontalCard2
      ? ETitlePartsTheme.Light
      : ETitlePartsTheme.Dark;

  const isXl = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
  );
  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );
  const getSize = (): ETitlePartsSize => {
    if (isXl) {
      return ETitlePartsSize.Xl;
    }
    if (isMd) {
      return ETitlePartsSize.L;
    }
    return ETitlePartsSize.Ml;
  };
  const hasContent = richTitle || description || image || !!iconList?.length;
  return (
    <div
      className={clsx('flex max-w-[51rem] gap-4', {
        'p-8': paddings === EHorizontalCardV2Paddings.Yes,
        'pt-8': paddings === EHorizontalCardV2Paddings.OnlyTop,
        'mx-auto flex-col items-center':
          alignContent === EHorizontalCardV2AlignContent.Center,
        'items-start': alignContent === EHorizontalCardV2AlignContent.Left,
        'bg-surface-50': theme === EHorizontalCardV2Theme.HorizontalCard1,
        'bg-surface-100': theme === EHorizontalCardV2Theme.HorizontalCard3,
      })}
    >
      {alignContent === EHorizontalCardV2AlignContent.Center
        ? (image || !!iconList?.length) && (
            <div className={'flex gap-6'}>
              {!!iconList?.length &&
                iconList.map((icon, index) => {
                  return (
                    icon && <Icon {...iconProps} key={index} icon={icon} />
                  );
                })}
              {image && (
                <ImageWithPlaceholder
                  className={clsx('max-w w-auto min-w-8 max-w-64', {
                    'h-8': imageHeight === EHorizontalCardV2ImageHeight.L,
                    'h-14': imageHeight === EHorizontalCardV2ImageHeight.Xl,
                  })}
                  {...image}
                />
              )}
            </div>
          )
        : firstIcon && <Icon {...iconProps} icon={firstIcon} />}

      <div
        className={clsx('flex flex-col', {
          'items-center text-center':
            alignContent === EHorizontalCardV2AlignContent.Center,
        })}
      >
        {richTitle && (
          <TitlePart
            position={titlePartPosition}
            size={getSize()}
            theme={titlePartTheme}
          >
            {richTitle}
          </TitlePart>
        )}
        {description && (
          <div
            className={clsx(
              'font-regular mt-4 text-base leading-mega-loose',
              'prose-a:text-brand-500 prose-a:hover:text-brand-550',
              'prose-p:pb-4 last:prose-p:pb-0',
              'prose-li:pb-2 last:prose-li:pb-0',
              'max-w-[38rem]',
              {
                'text-interface-800':
                  theme === EHorizontalCardV2Theme.HorizontalCard1 ||
                  theme === EHorizontalCardV2Theme.HorizontalCard3,
                'text-interface-50/80 [&_.custom-li]:before:bg-brand-50':
                  theme === EHorizontalCardV2Theme.HorizontalCard2,
                'mx-auto prose-ul:text-start prose-li:mx-auto prose-li:w-fit':
                  alignContent === EHorizontalCardV2AlignContent.Center,
              },
            )}
          >
            {description}
          </div>
        )}
        {buttons && buttons.length !== 0 && (
          <div
            className={clsx('flex flex-wrap items-center gap-6', {
              'mt-7': hasContent,
            })}
          >
            {buttons.map((button, index) => (
              <Button key={index} {...button} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalCardV2;
