import clsx from 'clsx';
import React, { useState } from 'react';

import {
  AccordionCardV2,
  EAccordionV2ImagePositionDesktop,
  TAccordionCardV2,
  EAccordionV2IconPosition,
  EAccordionV2ImagePositionMobile,
  EAccordionV2RowHorizontalScroll,
} from '../../atoms/AccordionCardV2';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { Container } from '../../sections/Container';

export type TAccordionCardsV2Props = {
  horizontalScroll?: EAccordionV2RowHorizontalScroll;
  cards: TAccordionCardV2[];
};

export const AccordionCardsV2: React.FC<TAccordionCardsV2Props> = props => {
  const { cards = [], horizontalScroll = EAccordionV2RowHorizontalScroll.No } =
    props;

  const [openedCardIndex, setOpenedCardIndex] = useState(0);

  if (!Array.isArray(cards)) {
    return null;
  }

  const openedCard = openedCardIndex === -1 ? null : cards[openedCardIndex];
  const imageProps = openedCard?.image ? openedCard.image : null;
  const imgCustomBackground = openedCard?.lightBackground
    ? openedCard.lightBackground
    : '#F6F6F9';
  const cardImagePositionDesktop = openedCard?.cardImagePositionDesktop
    ? openedCard?.cardImagePositionDesktop
    : EAccordionV2ImagePositionDesktop.Top;

  const onClickAccordionCard = (index: number): void => {
    setOpenedCardIndex(value => (value === index ? -1 : index));
  };

  return (
    <section>
      <Container isNoPaddingOnMobile>
        <div className='grid gap-y-4 xl:grid-cols-[auto_42.5rem] xl:gap-x-4  xl:px-4 2xl:grid-cols-[auto_57.5rem] 2xl:px-0'>
          <div className='w-full overflow-hidden'>
            <div
              className={clsx(
                'gap-x-4 gap-y-8 px-4 xl:col-span-3 xl:grid-cols-1 xl:gap-y-2 xl:px-0',
                horizontalScroll === EAccordionV2RowHorizontalScroll.No
                  ? 'grid grid-cols-1 md:grid-cols-2'
                  : 'scrollbar-hidden flex flex-row flex-nowrap justify-start overflow-auto scroll-smooth  xl:grid',
              )}
            >
              {cards.map((card, index) => {
                return (
                  <AccordionCardV2
                    key={index}
                    isActiveClosable={false}
                    isOpened={index === openedCardIndex}
                    title={card.title}
                    icon={card?.icon}
                    cardIconPosition={
                      card?.cardIconPosition as EAccordionV2IconPosition
                    }
                    image={card?.image}
                    cardImagePositionMobile={
                      card?.cardImagePositionMobile as EAccordionV2ImagePositionMobile
                    }
                    lightBackground={imgCustomBackground}
                    bulletsOptions={card.bulletsOptions}
                    bottomDescription={card?.bottomDescription}
                    cardDescriptionList={card?.cardDescriptionList}
                    onClick={() => onClickAccordionCard(index)}
                  />
                );
              })}
            </div>
          </div>
          <div
            className={clsx(
              'col-span-2 hidden overflow-hidden rounded-[2rem] xl:col-span-1 xl:flex xl:justify-center',
              cardImagePositionDesktop ===
                EAccordionV2ImagePositionDesktop.Top && 'xl:items-start',
              cardImagePositionDesktop ===
                EAccordionV2ImagePositionDesktop.Middle && 'xl:items-center',
              cardImagePositionDesktop ===
                EAccordionV2ImagePositionDesktop.Bottom && 'xl:items-end',
            )}
            style={{
              backgroundColor: imgCustomBackground,
            }}
          >
            {imageProps && (
              <ImageWithPlaceholder
                key={openedCardIndex}
                {...imageProps}
                className='w-full'
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};
