export enum EAccordionV2IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum EAccordionV2ImagePositionMobile {
  Top = 'top',
  Bottom = 'bottom',
}

export enum EAccordionV2ImagePositionDesktop {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum EAccordionV2RowHorizontalScroll {
  Yes = 'yes',
  No = 'no',
}
