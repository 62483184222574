import clsx from 'clsx';
import React from 'react';

import CellWithLink from './components/CellWithLink';
import Button from '../../atoms/Button/Button';

type TCellWithLinksRowProps = {
  items?: React.ComponentProps<typeof Button>[];
  animation?: ECellWithLinksAnimation;
};

export enum ECellWithLinksAnimation {
  On = 'on',
  Off = 'off',
}

export const CellWithLinksRow: React.FC<TCellWithLinksRowProps> = props => {
  const { items = [], animation } = props;
  if (!items?.length) {
    return null;
  }

  return (
    <div className='p-px'>
      <div
        className={clsx(
          'grid grid-cols-2 gap-px bg-[-1px] bg-no-repeat outline outline-1 outline-control-200 md:grid-cols-3 2xl:grid-cols-4',
          'md:bg-[repeating-linear-gradient(to_right,rgb(var(--color-control-200)/1),rgb(var(--color-control-200)/1)_1px,transparent_1px,transparent_calc((100%+1px)/3))]',
          '2xl:bg-[repeating-linear-gradient(to_right,rgb(var(--color-control-200)/1),rgb(var(--color-control-200)/1)_1px,transparent_1px,transparent_calc((100%+1px)/4))]',
        )}
      >
        {items.map((item, index) => (
          <CellWithLink
            hasAnimation={animation === ECellWithLinksAnimation.On}
            action={item}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
