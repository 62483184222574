import clsx from 'clsx';
import React from 'react';

import { EQuoteWithCardStatsSize } from './constants';

export type TCardWithQuoteStatisticsProps = {
  statisticCards: TCardWithQuoteStatisticsCards[];
  size?: `${EQuoteWithCardStatsSize}`;
};

export type TCardWithQuoteStatisticsCards = {
  dataValue?: string;
  dataSubtitle?: string;
  dataDescription?: string;
};

const CardWithQuoteStatistics: React.FC<
  TCardWithQuoteStatisticsProps
> = props => {
  const { size = EQuoteWithCardStatsSize.L, statisticCards } = props;
  return (
    <div
      className={clsx(
        'mt-12 flex w-full flex-col gap-8 md:mt-16 md:flex-row md:flex-wrap md:justify-center md:gap-x-4',
      )}
    >
      {statisticCards.map((item, key) => (
        <div
          className={clsx('w-full text-center', {
            'md:basis-[calc(100%/3-2rem/3)]':
              size === EQuoteWithCardStatsSize.L,
            'md:basis-[calc(25%-0.75rem)]': size === EQuoteWithCardStatsSize.M,
          })}
          key={key}
        >
          {item?.dataValue && (
            <p
              className={clsx(
                'text-5xl font-bold leading-[2.5rem] text-interface-1000 ',
                {
                  'xl:text-8xl xl:leading-relaxed':
                    size === EQuoteWithCardStatsSize.L,
                  'xl:text-7xl xl:leading-snug':
                    size === EQuoteWithCardStatsSize.M,
                },
              )}
            >
              {item?.dataValue}
            </p>
          )}
          {item?.dataSubtitle && (
            <p
              className={clsx('font-semibold text-interface-1000', {
                'text-lg leading-extra-loose xl:text-2xl xl:leading-[1.719rem]':
                  size === EQuoteWithCardStatsSize.L,
                'text-base leading-normal lg:text-lg xl:text-xl xl:leading-extra-loose  ':
                  size === EQuoteWithCardStatsSize.M,
              })}
            >
              {item?.dataSubtitle}
            </p>
          )}
          {item?.dataDescription && (
            <p
              className={clsx(
                'mt-2 text-sm leading-mega-loose text-interface-800 md:px-0',
                {
                  'px-[0.219rem] xl:px-[0.313rem] 2xl:px-[2.313rem]':
                    size === EQuoteWithCardStatsSize.L,
                  '2xl:px-[1.625rem]': size === EQuoteWithCardStatsSize.M,
                },
              )}
            >
              {item?.dataDescription}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default CardWithQuoteStatistics;
