import { cva } from 'cva';
import { motion, useTransform } from 'framer-motion';
import React, { useEffect } from 'react';

import Blocks from './components/Blocks';
import Buttons from './components/Buttons';
import Description from './components/Description';
import Eyebrows from './components/Eyebrows';
import Image from './components/Image';
import Spaces from './components/Spaces';
import Title from './components/Title';
import { EBannerCardBgV2Theme, EBannerCardsV2AlignImage } from './constants';
import { Icon } from '../../atoms/Icon';
import { EIconSize } from '../../atoms/Icon/Icon.constants';

import type { TBannerCardBgV2Props } from './types';

export const cardCVA = cva(
  'flex flex-row flex-wrap rounded-3xl lg:flex-nowrap 2xl:gap-4 overflow-hidden',
  {
    variants: {
      theme: {
        [EBannerCardBgV2Theme.Theme1]: 'bg-surface-100',
        [EBannerCardBgV2Theme.Theme2]: 'bg-surface-50',
      },
    },
  },
);

const treashold = 0.95;
const stepOffset = 48;
const lastStepOffset = stepOffset / 2;

const BannerCardBgV2: React.FC<TBannerCardBgV2Props> = props => {
  const {
    blocks,
    eyebrows,
    icon,
    description,
    title,
    buttons,
    space,
    image,
    theme,
    alignImage,
    /* animations */
    isAnimationEnabled,
    progress,
    range,
    targetScale,
    targetOpacity,
    idx,
    isLast,
  } = props;

  const scale = useTransform(progress, range, [1, targetScale]);
  const opacity = useTransform(progress, range, [1, targetOpacity]);
  const [isFinished, setIsFinished] = React.useState(false);

  useEffect(() => {
    if (!isAnimationEnabled) {
      return;
    }

    function updateOpacity(): void {
      const value = scale.get() < treashold;

      if (!isLast && isFinished !== value) {
        setIsFinished(value);
      }
    }

    const unsubscribe = scale.on('change', updateOpacity);

    return () => {
      unsubscribe();
    };
  }, [idx, scale, isFinished, isAnimationEnabled, isLast]);

  return (
    <motion.div
      style={
        isAnimationEnabled
          ? {
              transformOrigin: 'top center',
              scale,
              top: stepOffset * idx + (isLast ? lastStepOffset : 0),
              position: 'relative',
              opacity,
            }
          : {}
      }
    >
      <div className={cardCVA({ theme })}>
        {alignImage === EBannerCardsV2AlignImage.Left && (
          <Image image={image} />
        )}

        <div className='flex flex-col gap-6 p-6 lg:max-w-[22.5rem] xl:w-[30.5rem] xl:min-w-[30.5rem] xl:gap-8 xl:p-8 2xl:w-[25rem] 2xl:min-w-[25rem]'>
          {(icon || title || description) && (
            <div className='flex flex-col gap-4'>
              <Icon icon={icon} size={EIconSize.XxxL} />

              <Eyebrows eyebrows={eyebrows} />

              <Title title={title} shrink={isAnimationEnabled && isFinished} />

              <Description description={description} />
            </div>
          )}

          <Description description={description} />

          <Blocks blocks={blocks} />

          <Buttons buttons={buttons} />

          <Spaces space={space} />
        </div>

        {alignImage === EBannerCardsV2AlignImage.Right && (
          <Image image={image} />
        )}
      </div>
    </motion.div>
  );
};

export default BannerCardBgV2;
