import { baseTailwindConfig } from '@front/shared';
import { motion, useScroll } from 'framer-motion';
import React, { useRef } from 'react';
import { useMedia } from 'react-use';

import { EBannerCardsBgV2Animation } from './BannerCardsBgV2.constants';
import Container from '../../sections/Container/Container';
import {
  BannerCardBgV2,
  EBannerCardsV2AlignImage,
  TBannerCardBgV2Props,
} from '../BannerCardBgV2';

export type TBannerCardsBgV2Props = {
  cards?:
    | Omit<
        TBannerCardBgV2Props,
        | 'isAnimationEnabled'
        | 'progress'
        | 'range'
        | 'targetScale'
        | 'targetOpacity'
        | 'idx'
        | 'isLast'
      >[]
    | null;
  alignImage: EBannerCardsV2AlignImage;
  animation?: EBannerCardsBgV2Animation;
};

const SCALE_FACTOR = 0.05;
const OPACITY_FACTOR = 0.2;
const STEP_OFFSET = 48;

function ContainerWrapper({
  isAnimationEnabled,
  children,
}: {
  isAnimationEnabled: boolean;
  children: React.ReactNode;
}): React.ReactNode {
  if (!isAnimationEnabled) {
    return children;
  }

  return (
    <motion.div
      style={{
        position: 'sticky',
        top: 150,
      }}
    >
      {children}
    </motion.div>
  );
}

const BannerCardsBgV2: React.FC<TBannerCardsBgV2Props> = ({
  cards,
  alignImage,
  animation,
}) => {
  const container = useRef(null);
  const isXlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );

  const isAnimationEnabled =
    isXlBreakpoint && animation === EBannerCardsBgV2Animation.Enabled;

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  if (!cards?.length) {
    return null;
  }

  const count = cards.length;

  return (
    <section>
      <Container>
        <div
          className='relative flex flex-col justify-center gap-4'
          style={{
            paddingBottom: isAnimationEnabled ? STEP_OFFSET * (count - 1) : 0,
          }}
          ref={container}
        >
          {cards.map((card, index) => {
            const isLast = index === count - 1;

            const targetScale = 1 - (count - index) * SCALE_FACTOR;
            // calculate the target opacity
            // 2 is the number of cards that are visible
            const targetOpacity = 1 - (count - index - 2) * OPACITY_FACTOR;

            return (
              <ContainerWrapper isAnimationEnabled={isAnimationEnabled}>
                <BannerCardBgV2
                  {...card}
                  isAnimationEnabled={isAnimationEnabled}
                  alignImage={alignImage}
                  targetScale={targetScale}
                  targetOpacity={targetOpacity}
                  progress={scrollYProgress}
                  range={[index * (1 / count), 1]}
                  idx={index}
                  isLast={isLast}
                />
              </ContainerWrapper>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default BannerCardsBgV2;
