import React from 'react';

import Space from './Space';
import { TBannerCardBgV2Props } from '../types';

const Spaces: React.FC<Pick<TBannerCardBgV2Props, 'space'>> = props => {
  const { space } = props;

  if (!space?.length) {
    return null;
  }

  return (
    <div className='inline-grid grid-cols-1 gap-8'>
      {space?.map((block, index) => (
        <Space
          key={index}
          type={block.type}
          image={block.image}
          text={block.text}
        />
      ))}
    </div>
  );
};

export default Spaces;
