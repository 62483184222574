import React from 'react';

import Block from './Block';
import { THorizontalCardBgV2Props } from '../types';

const Blocks: React.FC<Pick<THorizontalCardBgV2Props, 'blocks'>> = props => {
  const { blocks } = props;

  if (!blocks?.length) {
    return null;
  }

  return (
    <div className='inline-grid grid-cols-1 gap-4 gap-8 pt-8 md:grid-cols-2'>
      {blocks?.map((block, index) => (
        <Block
          key={`${block}-${index}`}
          icon={block.icon}
          title={block.title}
          description={block.description}
        />
      ))}
    </div>
  );
};

export default Blocks;
