import React from 'react';

import { Button } from '../../../atoms/Button';
import { THorizontalCardBgV2Props } from '../types';

const Buttons: React.FC<Pick<THorizontalCardBgV2Props, 'buttons'>> = props => {
  const { buttons } = props;

  if (!buttons?.length) {
    return null;
  }

  return (
    <div className='flex flex-row flex-col flex-wrap items-start gap-5 pt-7 md:flex-row lg:items-center'>
      {buttons.map((button, index) => (
        <Button key={index} {...button}>
          {button.children}
        </Button>
      ))}
    </div>
  );
};

export default Buttons;
