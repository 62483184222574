import clsx from 'clsx';
import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { THorizontalCardBgV2TypeA } from '../types';

const Image: React.FC<
  Pick<THorizontalCardBgV2TypeA, 'image' | 'imageRounding' | 'imageBackground'>
> = props => {
  const { image, imageRounding, imageBackground } = props;

  if (!image) {
    return null;
  }

  return (
    <div
      className={clsx(
        'relative inline-flex aspect-square w-full items-center justify-center gap-4 overflow-hidden xl:min-w-[334px] xl:max-w-[334px]  xl:rounded-3xl xl:bg-surface-100 xl:pb-0 2xl:min-w-[400px] 2xl:max-w-[400px]',
        {
          'rounded-3xl': imageRounding,
        },
      )}
      style={
        imageBackground
          ? {
              backgroundColor: imageBackground,
            }
          : {}
      }
    >
      <ImageWithPlaceholder {...image} className='object-contain' />
    </div>
  );
};

export default Image;
