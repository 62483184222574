import clsx from 'clsx';
import { cva } from 'cva';
import { ImageProps } from 'next/image';
import React from 'react';

import {
  ETitleCardBgAlignIcon,
  ETitleCardBgTheme,
  ETitleCardBgTruncateText,
  ETitleCardBgType,
} from './TitleCardBg.constants';
import { ActionWrapper } from './components/ActionWrapper';
import { defineIconSize, defineTitleSize, defineTitleTheme } from './utils';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { ETitlePartsSize, TitlePart } from '../TitlePart';

export type TTitleCardBgProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  leftIcon: ImageProps | null;
  rightIcon: ImageProps | null;
  type: ETitleCardBgType;
  size: ETitlePartsSize;
  theme: ETitleCardBgTheme;
  truncateText?: ETitleCardBgTruncateText;
  alignIcons?: ETitleCardBgAlignIcon;
  buttons?: React.ComponentProps<typeof Button>[];
};

const cardCVA = cva('flex flex-row items-center gap-4', {
  variants: {
    size: {
      [ETitlePartsSize.M]: 'min-h-[125px] rounded-3xl',
      [ETitlePartsSize.Xs]: 'min-h-[64px] px-6 py-4 rounded-xl',
      [ETitlePartsSize.S]: 'rounded-3xl',
      [ETitlePartsSize.L]: '',
      [ETitlePartsSize.Xl]: '',
      [ETitlePartsSize.XxxxxxL]: '',
      [ETitlePartsSize.XxxxxL]: '',
      [ETitlePartsSize.XxxxL]: '',
      [ETitlePartsSize.XxxL]: '',
      [ETitlePartsSize.XxL]: '',
      [ETitlePartsSize.Ml]: '',
    },
    theme: {
      [ETitleCardBgTheme.Theme1]: 'bg-surface-100',
      [ETitleCardBgTheme.Theme2]: 'bg-surface-50',
      [ETitleCardBgTheme.Theme3]: 'bg-brand-500',
      [ETitleCardBgTheme.Theme4]: 'bg-accent-500',
      [ETitleCardBgTheme.Theme5]: 'bg-surface-800',
      [ETitleCardBgTheme.Theme6]: 'bg-surface-950',
      [ETitleCardBgTheme.Theme7]: 'bg-surface-50-tooltip/[.21]',
      [ETitleCardBgTheme.Theme8]: 'border-2 border-surface-200 bg-surface-50',
    },
    type: {
      [ETitleCardBgType.TypeA]: 'p-8 ',
      [ETitleCardBgType.TypeB]: 'p-6 ',
      [ETitleCardBgType.TypeC]: 'px-6 py-4 rounded-xl',
      [ETitleCardBgType.TypeD]: 'px-6 py-4 rounded-xl',
    },
    hasAction: {
      true: 'transition-opacity duration-200 hover:opacity-60',
      false: '',
    },
  },
  compoundVariants: [
    {
      type: ETitleCardBgType.TypeB,
      size: ETitlePartsSize.S,
      className: 'p-6 rounded-3xl',
    },
  ],
});

const TitleCardBg: React.FC<TTitleCardBgProps> = props => {
  const {
    title,
    description,
    size,
    type,
    theme,
    buttons,
    leftIcon,
    rightIcon,
    truncateText,
    alignIcons,
  } = props;

  const titleTheme = defineTitleTheme(theme);
  const titleSize = defineTitleSize(type, size);
  const iconSize = defineIconSize(type, size);
  const hasAction = Boolean(buttons?.length);
  const iconClasses = clsx('shrink-0 overflow-hidden', {
    'align-self-start': alignIcons === ETitleCardBgAlignIcon.Top,
  });

  return (
    <ActionWrapper button={buttons?.[0]}>
      <div className={cardCVA({ theme, type, hasAction, size })}>
        {leftIcon && (
          <div className={iconClasses}>
            <Icon icon={leftIcon} size={iconSize} />
          </div>
        )}

        <div
          className={clsx('flex grow flex-col', {
            'gap-1': size !== ETitlePartsSize.Xs,
          })}
        >
          <TitlePart size={titleSize} theme={titleTheme}>
            {title}
          </TitlePart>
          {description && (
            <div
              className={clsx('text-control-800 text-sm leading-mega-loose', {
                'overflow-ellipsis':
                  truncateText === ETitleCardBgTruncateText.Line,
              })}
            >
              {description}
            </div>
          )}
        </div>

        {rightIcon && (
          <div className={iconClasses}>
            <Icon icon={rightIcon} size={iconSize} />
          </div>
        )}
      </div>
    </ActionWrapper>
  );
};

export default TitleCardBg;
