import clsx from 'clsx';
import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { TBannerCardBgV2Props } from '../types';

const Image: React.FC<Pick<TBannerCardBgV2Props, 'image'>> = props => {
  const { image } = props;

  if (!image) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center gap-4 xl:max-w-full xl:rounded-3xl xl:pb-0',
      )}
    >
      <ImageWithPlaceholder
        {...image}
        className='h-full w-full object-contain'
      />
    </div>
  );
};

export default Image;
